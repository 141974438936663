<template>
  <b-row align-h="center" v-if="dataLoading">
    <b-spinner
      class="m-5"
      style="width: 4rem; height: 4rem"
      type="grow"
      variant="primary"
      label="Spinning"
    ></b-spinner>
  </b-row>

  <div v-else>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="showSettings"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="showSettings = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col md="4" class="pr-0">
            <b-form-checkbox id="checkbox1" v-model="settings.printSlip">
              <h5 class="">Print Slip</h5>
            </b-form-checkbox>
          </b-col>
          <b-col md="4" class="pr-0">
            <b-form-checkbox id="checkbox2" v-model="settings.sendSMS">
              <h5 class="">Send SMS</h5>
            </b-form-checkbox>
          </b-col>
          <b-col md="4" v-if="noticeObj" class="pr-0">
            <b-form-checkbox v-model="noticeObj.valueBool">
              <h5 class="">{{ noticeObj.value }}</h5>
            </b-form-checkbox>
          </b-col>
          <b-col md="12" v-if="cHeadObj" class="mt-1">
            <b-form-checkbox v-model="cHeadObj.valueBool">
              <h5 class="">{{ cHeadObj.value }}</h5>
            </b-form-checkbox>
          </b-col>

          <b-col md="12" class="mt-1">
            <b-form-group
              label="Print by"
              invalid-feedback="Print by is required."
              ref="printby"
            >
              <v-select
                ref="printby"
                v-model="settings.printBy"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="printOptions"
                :clearable="false"
                label="text"
                :reduce="(val) => val.value"
                placeholder="Select"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" class="" v-if="subObj && subObj.valueBool">
            <b-form-group
              invalid-feedback="Fees to Print is required."
              ref="to_print"
            >
              <div class="d-flex justify-content-between pb-50">
                <label class="bv-no-focus-ring col-form-label p-0"
                  >Fees to Print</label
                >
                <b-badge
                  variant="primary"
                  pill
                  class="cursor-pointer"
                  @click="toPrint = []"
                  >Clear All</b-badge
                >
              </div>
              <v-select
                class="set_multi"
                ref="to_print"
                multiple
                :closeOnSelect="false"
                v-model="toPrint"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[{ id: 0, feeType: 'All' }, ...feeTypes]"
                label="feeType"
                :reduce="(val) => val.id"
                placeholder="Select"
                @input="setAll()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Issue Date"
              invalid-feedback="issue date is required."
              ref="issue"
            >
              <flat-pickr
                ref="issue"
                :config="config"
                v-model="settings.issueDate"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Due Date"
              invalid-feedback="due date is required."
              ref="due"
            >
              <flat-pickr
                ref="due"
                :config="config"
                v-model="settings.dueDate"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Expiry Date"
              invalid-feedback="expiry date is required."
              ref="exp"
            >
              <flat-pickr
                ref="exp"
                :config="config"
                v-model="settings.expiryDate"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Late fee"
              invalid-feedback="Late fee is required."
              ref="late"
            >
              <b-form-input
                type="number"
                v-model="settings.lateFee"
                placeholder="Enter late fee"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Timely Discount"
              invalid-feedback="timely discount is required."
              ref="dis"
            >
              <b-form-input
                type="number"
                v-model="settings.timelyDiscount"
                placeholder="Enter timely discount"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Bank Name"
              invalid-feedback="Bank Name is required"
              ref="bank"
            >
              <b-form-input
                v-model="settings.bankName"
                placeholder="Enter bank name"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Title of account"
              invalid-feedback="Title of account is required"
              ref="acc_title"
            >
              <b-form-input
                v-model="settings.titleOfAccount"
                placeholder="Enter title of account"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Account Number"
              invalid-feedback="Account Number is required"
              ref="accNo"
            >
              <b-form-input
                v-model="settings.accountNumber"
                placeholder="Enter account number"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Fee Challan Instructions"
              invalid-feedback="Please enter fee challan instruction"
              ref="instr"
            >
              <b-form-textarea
                ref="instr"
                placeholder="Please write instruction for fee challan."
                v-model="settings.instructions"
                rows="4"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="saveSettings()"
              :disabled="saving"
              block
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <!-- <b-row align-h="center" v-if="dataLoading">
        <b-spinner
          class="m-5"
          style="width: 4rem; height: 4rem"
          type="grow"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </b-row> -->

      <b-row>
        <b-col class="d-flex justify-content-between">
          <h3>Create Fee</h3>
          <div>
            <b-button
              variant="success"
              class="mr-1"
              :disabled="holding"
              @click="holdFee()"
            >
              <!-- <b-spinner v-if="holding" small type="grow" /> -->
              <span>Hold</span>
            </b-button>
            <b-button
              variant="success"
              class="mr-1"
              :disabled="holding"
              @click="unholdFee()"
            >
              <!-- <b-spinner v-if="holding" small type="grow" /> -->
              <span>UnHold</span>
            </b-button>

            <feather-icon
              icon="SettingsIcon"
              size="20"
              class="cursor-pointer"
              @click="showSettings = true"
            />
            <!-- <feather-icon
              v-if="!this.$store.state.creatorStates[0]"
              class="cursor-pointer ml-50"
              icon="ArrowDownIcon"
              size="26"
              @click="show(0)"
            />
            <feather-icon
              v-else
              class="cursor-pointer ml-50"
              icon="ArrowUpIcon"
              size="26"
              @click="hide(0)"
            /> -->
          </div>
        </b-col>
        <b-col md="12">
          <hr />
        </b-col>
        <b-collapse
          v-model="this.$store.state.creatorStates[0]"
          class="col-md-12"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Select Months"
                invalid-feedback="Please select a month."
                ref="months"
              >
                <div
                  style="
                    border: 1px solid lightgray;
                    padding: 5px;
                    border-radius: 5px;
                  "
                >
                  <b-button
                    style="margin: 7px"
                    class="btn-icon"
                    v-for="mon in months"
                    :key="mon.id"
                    :variant="
                      variantMonth(mon.name) ? 'primary' : 'outline-primary'
                    "
                    @click="selectMonth(mon)"
                  >
                    {{ mon.name }}
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12" class="">
              <b-form-group
                label="Select Fee Type"
                invalid-feedback="Please select fee type."
                ref="type"
              >
                <a class="my-buttons font-weight-bold">
                  <span v-if="!showAll" @click="showAll = true">Show More</span>
                  <span v-else @click="showAll = false">Show Less</span>
                </a>

                <!-- <feather-icon
              v-if="!showAll"
              v-b-tooltip.hover.left
              title="Show"
              class="my-buttons"
              icon="ArrowDownIcon"
              size="26"
              @click="showAll = true"
            />
            <feather-icon
              v-else
              v-b-tooltip.hover.left
              title="Hide"
              class="my-buttons"
              icon="ArrowUpIcon"
              size="26"
              @click="showAll = false"
            /> -->
                <div
                  :class="{ 'my-collapsing': !showAll }"
                  style="
                    border: 1px solid lightgray;
                    padding: 5px;
                    border-radius: 5px;
                  "
                >
                  <b-button
                    style="margin: 5px"
                    class="btn-icon"
                    v-for="fee in feeTypes"
                    :key="fee.id"
                    :variant="
                      variantFeetype(fee.id) ? 'primary' : 'outline-primary'
                    "
                    @click="selectFeetype(fee.id, fee.recurrence)"
                  >
                    {{ fee.feeType }}
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              class=""
              v-if="subObj && subObj.valueBool && showOnBehalf"
            >
              <b-form-group
                label="Late on Behalf of"
                invalid-feedback="Please select fee type."
                ref="late_type"
              >
                <a class="my-buttons font-weight-bold">
                  <span v-if="!showAllLate" @click="showAllLate = true"
                    >Show More</span
                  >
                  <span v-else @click="showAllLate = false">Show Less</span>
                </a>

                <!-- <feather-icon
              v-if="!showAll"
              v-b-tooltip.hover.left
              title="Show"
              class="my-buttons"
              icon="ArrowDownIcon"
              size="26"
              @click="showAll = true"
            />
            <feather-icon
              v-else
              v-b-tooltip.hover.left
              title="Hide"
              class="my-buttons"
              icon="ArrowUpIcon"
              size="26"
              @click="showAll = false"
            /> -->
                <div
                  :class="{ 'my-collapsing': !showAllLate }"
                  style="
                    border: 1px solid lightgray;
                    padding: 5px;
                    border-radius: 5px;
                  "
                >
                  <b-button
                    style="margin: 5px"
                    class="btn-icon"
                    v-for="fee in feeTypes.filter(
                      (el) => el.recurrence != 'after due date'
                    )"
                    :key="fee.id"
                    :variant="
                      myObj.onBehalf == fee.id ? 'primary' : 'outline-primary'
                    "
                    @click="selectOnBehalf(fee.id)"
                  >
                    {{ fee.feeType }}
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Select Class"
                invalid-feedback="class is required."
                ref="class"
              >
                <v-select
                  ref="class"
                  multiple
                  :closeOnSelect="false"
                  v-model="classID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="classesOptions"
                  :clearable="false"
                  label="name"
                  :reduce="(val) => val.id"
                  @input="FillSections()"
                  placeholder="Select class"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Select Section"
                invalid-feedback="section is required."
                ref="section"
              >
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="secID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="admittedSections"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  @input="checkSections()"
                  ref="section"
                  label="section"
                  placeholder="Select section"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                label="Select year"
                invalid-feedback="year is required."
                ref="year"
              >
                <v-select
                  v-model="myObj.year"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="years"
                  :clearable="false"
                  ref="year"
                  label="name"
                  placeholder="Select year"
                />
              </b-form-group>
            </b-col>
            <b-col class="text-right" md="12">
              <b-button
                variant="success"
                :disabled="request"
                class="ml-1"
                @click="saveFee()"
              >
                <div v-if="request">
                  <b-spinner
                    small
                    variant="light"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </div>
                <span v-else>Create Fee</span>
              </b-button>

              <b-button
                :disabled="requestDelete"
                variant="danger"
                class="ml-1"
                @click="undo()"
              >
                <div v-if="requestDelete">
                  <b-spinner
                    small
                    variant="light"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </div>
                <span v-else>Remove Fee</span>
              </b-button>

              <b-button
                variant="outline-secondary"
                class="ml-1"
                @click="checkPrint('print')"
                :disabled="iPrint"
              >
                <b-spinner v-if="iPrint" small type="grow" />
                <feather-icon v-if="!iPrint" icon="PrinterIcon" class="mr-50" />
                <span v-if="!iPrint"> Print Challan </span>
              </b-button>

              <b-button
                class="ml-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                @click="checkPrint('whatsapp')"
                :disabled="iMsg"
              >
                <b-spinner v-if="iMsg" small type="grow" />
                <feather-icon v-if="!iMsg" icon="MailIcon" class="mr-50" />
                <span v-if="!iMsg">WhatsApp</span>
              </b-button>
            </b-col>
          </b-row>
        </b-collapse>
      </b-row>
    </b-card>

    <b-card v-if="false">
      <b-row>
        <b-col class="d-flex justify-content-between">
          <h3>Send Message to Defaulters</h3>
          <feather-icon
            v-if="!this.$store.state.creatorStates[1]"
            class="cursor-pointer"
            icon="ArrowDownIcon"
            size="26"
            @click="show(1)"
          />
          <feather-icon
            v-else
            class="cursor-pointer"
            icon="ArrowUpIcon"
            size="26"
            @click="hide(1)"
          />
        </b-col>
        <b-col md="12">
          <hr />
        </b-col>
        <b-collapse
          v-model="this.$store.state.creatorStates[1]"
          class="col-md-12"
        >
          <b-row>
            <b-col md="12">
              <b-form-group label="Filter Options">
                <b-button
                  class="btn-icon mr-1"
                  v-for="item in filterOptions"
                  :key="item.value"
                  :variant="
                    msgObj.filterBy == item.value
                      ? 'primary'
                      : 'outline-primary'
                  "
                  @click="msgObj.filterBy = item.value"
                  v-b-tooltip.hover.top
                  :title="item.tooltip"
                >
                  {{ item.text }}
                </b-button>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Select Months"
                invalid-feedback="Please select a month."
                ref="msg_months"
              >
                <div
                  style="
                    border: 1px solid lightgray;
                    padding: 5px;
                    border-radius: 5px;
                  "
                >
                  <b-button
                    style="margin: 7px"
                    class="btn-icon"
                    :variant="
                      msgObj.months.includes(0) ? 'primary' : 'outline-primary'
                    "
                    @click="selectMsgMonth(0)"
                  >
                    All
                  </b-button>
                  <b-button
                    style="margin: 7px"
                    class="btn-icon"
                    v-for="mon in months"
                    :key="mon.id"
                    :variant="
                      msgObj.months.includes(mon.id)
                        ? 'primary'
                        : 'outline-primary'
                    "
                    @click="selectMsgMonth(mon.id)"
                  >
                    {{ mon.name }}
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12" class="">
              <b-form-group
                label="Select Fee Type"
                invalid-feedback="Please select fee type."
                ref="msg_type"
              >
                <a class="my-buttons font-weight-bold">
                  <span v-if="!showAllMsg" @click="showAllMsg = true"
                    >Show More</span
                  >
                  <span v-else @click="showAllMsg = false">Show Less</span>
                </a>
                <div
                  :class="{ 'my-collapsing': !showAllMsg }"
                  style="
                    border: 1px solid lightgray;
                    padding: 5px;
                    border-radius: 5px;
                  "
                >
                  <b-button
                    style="margin: 5px"
                    class="btn-icon"
                    :variant="
                      msgObj.feesTypeIDs.includes(0)
                        ? 'primary'
                        : 'outline-primary'
                    "
                    @click="selectMsgFee(0)"
                  >
                    All
                  </b-button>
                  <b-button
                    style="margin: 5px"
                    class="btn-icon"
                    v-for="fee in feeTypes"
                    :key="fee.id"
                    :variant="
                      msgObj.feesTypeIDs.includes(fee.id)
                        ? 'primary'
                        : 'outline-primary'
                    "
                    @click="selectMsgFee(fee.id)"
                  >
                    {{ fee.feeType }}
                  </b-button>
                </div>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                label="Select Class"
                invalid-feedback="class is required."
                ref="msg_class"
              >
                <v-select
                  ref="class"
                  multiple
                  :closeOnSelect="false"
                  v-model="msgClassID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="classesOptions"
                  :clearable="false"
                  label="name"
                  :reduce="(val) => val.id"
                  @input="FillMsgSections()"
                  placeholder="Select class"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Select Section"
                invalid-feedback="section is required."
                ref="msg_section"
              >
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="msgSecID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="msgSections"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  @input="checkMsgSections()"
                  ref="section"
                  label="section"
                  placeholder="Select section"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                label="Select year"
                invalid-feedback="year is required."
                ref="year"
              >
                <v-select
                  v-model="msgObj.year"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="years"
                  :clearable="false"
                  ref="year"
                  label="name"
                  placeholder="Select year"
                />
              </b-form-group>
            </b-col>
            <b-col class="text-right" md="12">
              <b-button
                class="ml-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                :disabled="iMsg"
              >
                <!-- @click="checkPrint('whatsapp')" -->
                <b-spinner v-if="iMsg" small type="grow" />
                <feather-icon v-if="!iMsg" icon="MailIcon" class="mr-50" />
                <span v-if="!iMsg">Send</span>
              </b-button>
            </b-col>
          </b-row>
        </b-collapse>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
  BCollapse,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";
import { stat } from "fs";

export default {
  components: {
    //Multiselect,
    BModal,
    BCard,
    BRow,
    BBadge,
    BTable,
    flatPickr,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BForm,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormTextarea,
    BCollapse,
    vSelect,
    TheMask,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
      this.loadSettings();
      this.loadSubSetting();
    }
    // this.LoadData();
    // this.loadSettings();
    // this.loadSubSetting();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.gridData.filter((pro) => {
        return pro.feeType.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      iMsg: false,
      iPrint: false,
      showAll: false,
      showAllLate: false,
      requestDelete: false,
      myDepartments: [],
      request: false,
      saving: false,
      colorDep: [],
      visibility1: false,
      feeTypeOptions: ["one time", "monthly", "yearly", "after due date"],

      sidebarTitle: "Add Class",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "Class", key: "cls" },
        { label: "feeType", key: "feeType" },
        { label: "amount", key: "amount" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      gridData: [],
      amountField: false,
      amountObj: {},
      myAmount: 0,
      mydefaultAmount: 0,
      selected: null,
      rangeDate: null,
      prevObj: {},
      myObj: {
        id: 0,
        year: new Date().getFullYear(),
        userID: this.$store.state.userData.userID,
        feesTypeIDs: [],
        months: [],
        classIDs: [],
        secIDs: [],
        campusID: this.$store.state.userData.cId,
        onBehalf: 0,
      },
      classID: [0],
      secID: [0],
      assignedClass: null,
      feeTypes: [],
      classes: [],
      classesOptions: [{ name: "All Classes", id: 0 }],
      admittedSections: [{ section: "All Sections", id: 0 }],
      selectedMonths: [],
      months: [
        {
          id: 1,
          name: "Jan",
        },
        {
          id: 2,
          name: "Feb",
        },
        {
          id: 3,
          name: "Mar",
        },
        {
          id: 4,
          name: "Apr",
        },
        {
          id: 5,
          name: "May",
        },
        {
          id: 6,
          name: "Jun",
        },
        {
          id: 7,
          name: "Jul",
        },
        {
          id: 8,
          name: "Aug",
        },
        {
          id: 9,
          name: "Sep",
        },
        {
          id: 10,
          name: "Oct",
        },
        {
          id: 11,
          name: "Nov",
        },
        {
          id: 12,
          name: "Dec",
        },
      ],
      years: [
        2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
        2027, 2028, 2029, 2030,
      ],
      showSettings: false,
      settings: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        issueDate: null,
        dueDate: null,
        expiryDate: null,
        lateFee: 0,
        timelyDiscount: 0,
        printSlip: false,
        sendSMS: false,
        bankName: "",
        accountNumber: "",
        printBy: "",
        titleOfAccount: "",
      },
      printOptions: [
        {
          text: "Student",
          value: "student",
        },
        {
          text: "Family",
          value: "family",
        },
      ],
      timeout: 10000,
      current: 0,
      subObj: null,
      showOnBehalf: false,
      toPrint: [],
      noticeObj: null,
      cHeadObj: null,
      filterOptions: [
        {
          text: "Include",
          tooltip: "Include filter will include the selected options",
          value: "include",
        },
        {
          text: "Exclude",
          tooltip: "Exclude filter will exclude the selected options",
          value: "exclude",
        },
      ],
      msgObj: {
        filterBy: "include",
        year: new Date().getFullYear(),
        userID: this.$store.state.userData.userID,
        feesTypeIDs: [0],
        months: [0],
        classIDs: [],
        secIDs: [],
        campusID: this.$store.state.userData.cId,
      },
      showAllMsg: false,
      msgClassID: [0],
      msgSecID: [0],
      msgSections: [{ section: "All Sections", id: 0 }],
      holding: false,
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({
      get: "get",
      post: "post",
      postObj: "postObj",
      put: "put",
      delete: "delete",
    }),

    show(ind) {
      if (ind == 0) {
        this.$store.commit("setCreatorStates", [
          true,
          this.$store.state.creatorStates[1],
        ]);
      } else {
        this.$store.commit("setCreatorStates", [
          this.$store.state.creatorStates[0],
          true,
        ]);
      }
    },
    hide(ind) {
      if (ind == 0) {
        this.$store.commit("setCreatorStates", [
          false,
          this.$store.state.creatorStates[1],
        ]);
      } else {
        this.$store.commit("setCreatorStates", [
          this.$store.state.creatorStates[0],
          false,
        ]);
      }
    },

    selectMsgMonth(id) {
      if (id == 0) {
        this.msgObj.months = [0];
      } else {
        this.msgObj.months = this.msgObj.months.filter((el) => el != 0);
        if (this.msgObj.months.includes(id)) {
          this.msgObj.months = this.msgObj.months.filter((el) => el != id);
        } else {
          this.msgObj.months.push(id);
        }
      }
      // console.log(this.msgObj.months);
    },
    selectMsgFee(id) {
      if (id == 0) {
        this.msgObj.feesTypeIDs = [0];
      } else {
        this.msgObj.feesTypeIDs = this.msgObj.feesTypeIDs.filter(
          (el) => el != 0
        );
        if (this.msgObj.feesTypeIDs.includes(id)) {
          this.msgObj.feesTypeIDs = this.msgObj.feesTypeIDs.filter(
            (el) => el != id
          );
        } else {
          this.msgObj.feesTypeIDs.push(id);
        }
      }
      // console.log(this.msgObj.feesTypeIDs);
    },
    FillMsgSections() {
      if (this.msgClassID[0] == 0 && this.msgClassID.length !== 1) {
        this.msgClassID = this.msgClassID.filter((e) => e !== 0);
        // console.log(this.msgClassID);
        this.classes.forEach((elem) => {
          if (this.msgClassID.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.msgSections.push(el));
            } else {
              // console.log("null");
              this.msgSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.msgClassID[this.msgClassID.length - 1] !== 0 &&
        this.msgClassID.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.msgClassID.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.msgSections.push(el));
            } else {
              // console.log("null");
              this.msgSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.msgClassID[this.msgClassID.length - 1] == 0) {
        this.msgClassID = [0];
        // console.log(this.msgClassID);
        this.msgSections = [{ section: "All Sections", id: 0 }];
        this.msgSecID = [0];
      } else {
        this.msgSections = [{ section: "All Sections", id: 0 }];
        this.msgSecID = [0];
      }
      this.CheckClass();
    },
    checkMsgSections() {
      // console.log(this.msgSecID);
      if (this.msgSecID[0] == 0 && this.msgSecID.length !== 1) {
        this.msgSecID = this.msgSecID.filter((e) => e !== 0);
        // console.log(this.msgSecID);
      } else if (this.msgSecID[this.msgSecID.length - 1] == 0) {
        this.msgSecID = [0];
        // console.log(this.msgSecID);
      }
      // this.CheckSec();
    },

    setAll() {
      if (this.toPrint.includes(0)) {
        this.toPrint = this.feeTypes.reduce((acc, c) => {
          acc.push(c.id);
          return acc;
        }, []);
      }
    },
    async loadSubSetting() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["fee_sub_categories", "fee_notice", "challan_with_header"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });

      this.subObj = res.find((el) => el.key == "fee_sub_categories");
      this.noticeObj = res.find((el) => el.key == "fee_notice");
      this.cHeadObj = res.find((el) => el.key == "challan_with_header");
      // console.log(this.noticeObj);
    },

    async loadSettings() {
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "FeeSettings/LoadData?campusID=" +
          this.$store.state.userData.cId +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      });
      if (myitem == null || myitem == "") {
        this.settings = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          issueDate: null,
          dueDate: null,
          expiryDate: null,
          lateFee: 0,
          timelyDiscount: 0,
          printSlip: false,
          sendSMS: false,
          bankName: "",
          accountNumber: "",
          printBy: "Family",
          titleOfAccount: "",
        };
      } else {
        this.settings = myitem;
      }
    },
    async saveSettings() {
      // console.log(this.settings);
      this.settings.lateFee = parseInt(this.settings.lateFee);
      this.settings.timelyDiscount = parseInt(this.settings.timelyDiscount);
      this.saving = true;

      var status = await this.post({
        url:
          this.$store.state.domain +
          "FeeSettings/Save?db=" +
          this.$store.state.userData.db,
        body: this.settings,
        message: "Settings saved successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });

      if (this.noticeObj || this.cHeadObj) {
        let data = [];
        if (this.noticeObj) data.push(this.noticeObj);
        if (this.cHeadObj) data.push(this.cHeadObj);

        var sett = await this.post({
          url:
            this.$store.state.domain +
            "Settings/SaveNew?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: data,
          importing: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (sett) {
          this.loadSubSetting();
        }
      }
      if (status) {
        this.showSettings = false;
      }
      this.saving = false;
    },
    variantFeetype(id) {
      return this.myObj.feesTypeIDs.includes(id);
    },
    selectFeetype(id, recurr) {
      if (this.myObj.feesTypeIDs.length == 0) {
        this.myObj.feesTypeIDs.push(id);
      } else {
        let data = this.myObj.feesTypeIDs.filter((el) => {
          return el !== id;
        });
        // console.log("data", data);
        if (data.length == this.myObj.feesTypeIDs.length) {
          this.myObj.feesTypeIDs.push(id);
        } else {
          this.myObj.feesTypeIDs = data;
        }
      }
      //
      if (recurr == "after due date") {
        if (this.variantFeetype(id) == true) {
          this.showOnBehalf = true;
        } else {
          this.showOnBehalf = false;
          this.myObj.onBehalf = 0;
        }
      }
      // console.log(this.myObj.feesTypeIDs);
    },
    selectOnBehalf(id) {
      if (this.myObj.onBehalf == id) this.myObj.onBehalf = 0;
      else this.myObj.onBehalf = id;
    },
    variantMonth(name) {
      return this.myObj.months.includes(name);
    },
    selectMonth(month) {
      if (this.myObj.months.length == 0) {
        this.myObj.months.push(month.name);
        this.selectedMonths.push(month.id);
      } else {
        let data = this.myObj.months.filter((el) => {
          return el !== month.name;
        });
        if (data.length == this.myObj.months.length) {
          this.myObj.months.push(month.name);
        } else {
          this.myObj.months = data;
        }

        //Selected Months
        let dataa = this.selectedMonths.filter((el) => {
          return el !== month.id;
        });
        if (dataa.length == this.selectedMonths.length) {
          this.selectedMonths.push(month.id);
        } else {
          this.selectedMonths = dataa;
        }
      }
      // console.log(this.selectedMonths);
    },
    FillSections() {
      // console.log(this.classID);

      //   if (this.classID.includes(0) && this.classID.length == 2) {
      //     this.classID = this.classID.filter((e) => e !== 0);
      //     console.log(this.classID);
      //   }
      if (this.classID[0] == 0 && this.classID.length !== 1) {
        this.classID = this.classID.filter((e) => e !== 0);
        // console.log(this.classID);
        this.classes.forEach((elem) => {
          if (this.classID.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.admittedSections.push(el));
            } else {
              // console.log("null");
              this.admittedSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.classID[this.classID.length - 1] !== 0 &&
        this.classID.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.classID.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.admittedSections.push(el));
            } else {
              // console.log("null");
              this.admittedSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.classID[this.classID.length - 1] == 0) {
        this.classID = [0];
        // console.log(this.classID);
        this.admittedSections = [{ section: "All Sections", id: 0 }];
        this.secID = [0];
      } else {
        this.admittedSections = [{ section: "All Sections", id: 0 }];
        this.secID = [0];
      }
      this.CheckClass();
    },
    checkSections() {
      // console.log(this.secID);
      if (this.secID[0] == 0 && this.secID.length !== 1) {
        this.secID = this.secID.filter((e) => e !== 0);
        // console.log(this.secID);
      } else if (this.secID[this.secID.length - 1] == 0) {
        this.secID = [0];
        // console.log(this.secID);
      }
      this.CheckSec();
    },

    AddOpen() {
      this.mydefaultAmount = 0;
      this.myObj = {
        id: 0,
        feeType: "",
        defaultAmount: 0,
        recurrence: "",
        campusID: this.$store.state.userData.cId,
      };
      this.amountField = false;
      // this.LoadClasses();
      this.sidebarTitle = "Add Fee";
      this.sidebarButton = "Save";
      this.visibility = true;
      var elem = this.$refs["recurrence"];
      elem.state = undefined;
      var elem = this.$refs["title"];
      elem.state = undefined;
      var elem = this.$refs["amount"];
      elem.state = undefined;
    },

    OpenDepartmentF() {
      this.visibility = true;
      //   this.depObj.id = 0;
      //   this.depObj.name = "";
      this.depObj.campusID = this.$store.state.userData.cId;
      this.sidebarTitle = "Add Fee";
      this.sidebarButton = "Save";
      //   var elem = this.$refs["depname"];
      //   elem.state = undefined;
    },

    EditAmount(obj) {
      // console.log("am_obj:", obj);
      this.amountObj = obj;
      this.myAmount = this.amountObj.amount;
      this.sidebarTitle = "Update Amount";
      this.sidebarButton = "Update";
      this.visibility1 = true;
      var elem = this.$refs["updatedamount"];
      elem.state = undefined;
    },
    CheckType() {
      var elem = this.$refs["type"];
      if (this.myObj.feesTypeIDs.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckMonths() {
      var elem = this.$refs["months"];
      if (this.myObj.months.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckYear() {
      var elem = this.$refs["year"];
      if (this.myObj.year == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckClass() {
      var elem = this.$refs["class"];
      if (this.classID.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSec() {
      var elem = this.$refs["section"];
      if (this.secID.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAmount() {
      var elem = this.$refs["amount"];
      if (this.mydefaultAmount == 0 || this.mydefaultAmount == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckUpdatedAmount() {
      var elem = this.$refs["updatedamount"];
      if (this.myAmount == 0 || this.myAmount == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckRecurrence() {
      var elem = this.$refs["recurrence"];
      if (this.myObj.recurrence == null || this.myObj.recurrence == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDepartment() {
      var elem = this.$refs["department"];
      if (this.myObj.departmentID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    hideSideBar() {
      this.visibility = false;
    },
    hideDepSideBar() {
      this.visibility1 = false;
    },

    Form() {
      this.$router.push("/apps/schoolinfo");
    },
    checkStatus() {
      if (this.myObj.Status == "") {
        return (this.errors.status = true);
      } else {
        return (this.errors.status = false);
      }
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "FeeType?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.feeTypes = await this.get(obj);
      // console.log("fee", this.feeTypes);

      var obj2 = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj2);
      // console.log("class", this.classes);
      this.classes.forEach((el) => this.classesOptions.push(el.cls));
      this.dataLoading = false;
    },
    async LoadGrid(id) {
      this.current = id;
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "FeeType/Load?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&feeTypeID=" +
          id,
        token: this.$store.state.userData.token,
      };
      this.gridData = await this.get(obj);
      if (this.gridData.length != 0) {
        this.totalRows = this.gridData.length;
      }
      this.dataLoading = false;
      // console.log("grid", this.gridData);
    },
    async LoadDepartments() {
      var obj = {
        url:
          this.$store.state.domain +
          "departments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.myDepartments = await this.get(obj);
    },

    async LoadClasses() {
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      // console.log("clas", this.classes);
    },

    async AmountUpdate() {
      this.CheckUpdatedAmount();
      if (this.CheckUpdatedAmount() == false) {
        return this.$bvToast.toast("Please enter the amount correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.amountObj.amount = parseInt(this.myAmount);
        // console.log(this.amountObj);
        this.request = true;
        var status = await this.put({
          url:
            this.$store.state.domain +
            "FeeAmount/" +
            this.amountObj.id +
            "?db=" +
            this.$store.state.userData.db,
          body: this.amountObj,
          message: "Amount updated successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        this.visibility1 = false;
        if (status) this.LoadGrid(this.amountObj.feeTypeID);
      }
    },
    async undo() {
      if (this.classID[0] !== 0) this.myObj.classIDs = this.classID;
      else this.myObj.classIDs = [];
      if (this.secID[0] !== 0) this.myObj.secIDs = this.secID;
      else this.myObj.secIDs = [];

      this.CheckType();
      this.CheckMonths();
      this.CheckYear();
      if (
        this.CheckType() != false &&
        this.CheckMonths() != false &&
        this.CheckYear() != false
      ) {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
        });

        if (result.isConfirmed) {
          this.requestDelete = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Fees/RemoveFee?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Fee removed successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });

          if (status) {
            this.prevObj = this.myObj;
            this.myObj = {
              id: 0,
              year: new Date().getFullYear(),
              userID: this.$store.state.userData.userID,
              feesTypeIDs: [],
              months: [],
              classIDs: [],
              secIDs: [],
              campusID: this.$store.state.userData.cId,
            };
            this.classID = [0];
            this.admittedSections = this.admittedSections = [
              { section: "All Sections", id: 0 },
            ];
            this.secID = [0];
            this.requestDelete = false;
          }
        }
      }
    },
    async whatsApp() {
      this.iMsg = true;

      await this.loadSettings();
      var controller = "GenerateChallan";
      if (
        this.settings.printBy != null &&
        this.settings.printBy.toLowerCase() == "student"
      )
        controller = "GenerateChallanStudent";

      try {
        if (this.classID[0] !== 0) this.myObj.classIDs = this.classID;
        else this.myObj.classIDs = [];
        if (this.secID[0] !== 0) this.myObj.secIDs = this.secID;
        else this.myObj.secIDs = [];

        var pObj = {
          fCode: 0,
          sID: 0,
          cID: this.classID,
          secID: this.secID,
          feeTypeID: this.myObj.feesTypeIDs,
          months: this.selectedMonths,
          year: this.myObj.year,
          familyWise: true,
          userID: this.$store.state.userData.userID,
          feeIDs: [],
        };

        if (pObj.cID[0] == 0) pObj.cID = [];
        if (pObj.secID[0] == 0) pObj.secID = [];
        // console.log(pObj);

        this.$bvToast.toast("Challan sending on WhatsApp", {
          title: "WhatsApp",
          variant: "success",
          toaster: "b-toaster-top-right",
        });

        await this.postObj({
          url:
            this.$store.state.domain +
            "feesChallan/" +
            controller +
            "?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&exp=true",
          body: pObj,
          message: "Challan sent on WhatsApp",
          context: this,
          ignoreTimeout: true,
          token: this.$store.state.userData.token,
        });
      } catch (error) {
      } finally {
        this.iMsg = false;
      }
    },
    async checkPrint(text) {
      let result = await Swal.fire({
        // title: "",
        html: `<div class='text-left'><p class='mb-50'>Your fee details are:</p>
         <b>Issue Date: </b>  ${new Date(
           this.settings.issueDate
         ).toLocaleDateString("en-UK", {
           year: "numeric",
           day: "numeric",
           month: "short",
         })}<br/>
        <b>Due Date: </b> ${new Date(this.settings.dueDate).toLocaleDateString(
          "en-UK",
          {
            year: "numeric",
            day: "numeric",
            month: "short",
          }
        )}<br/>
        <b>Expiry Date: </b> ${new Date(
          this.settings.expiryDate
        ).toLocaleDateString("en-UK", {
          year: "numeric",
          day: "numeric",
          month: "short",
        })}<br/>
        <p class='mt-1'>Are you sure you want to proceed?</p>
        </div>`,
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
        cancelButtonText: "Edit Settings",
      });

      // console.log(result);
      if (result.isConfirmed) {
        if (text == "print") this.print();
        else this.whatsApp();
      } else if (result.dismiss == "cancel") {
        this.showSettings = true;
      }
      // let current = new Date().getMonth() + 1;
      // let bySetting = new Date(this.settings.dueDate).getMonth() + 1;

      // if (current != bySetting) {
      // } else {
      //   if (text == "print") this.print();
      //   else this.whatsApp();
      // }
    },
    async print() {
      this.iPrint = true;

      await this.loadSettings();
      var ffc = 0;
      var controller = "GenerateChallan";
      let limit = 200;
      if (
        this.settings.printBy != null &&
        this.settings.printBy.toLowerCase() == "student"
      ) {
        controller = "GenerateChallanStudent";
        ffc = -1;
        limit = 200;
      }

      try {
        if (this.classID[0] !== 0) this.myObj.classIDs = this.classID;
        else this.myObj.classIDs = [];
        if (this.secID[0] !== 0) this.myObj.secIDs = this.secID;
        else this.myObj.secIDs = [];
        // console.log(this.myObj);

        var pObj = {
          fCode: 0,
          sID: 0,
          cID: this.classID,
          secID: this.secID,
          feeTypeID: this.myObj.feesTypeIDs,
          months: this.selectedMonths,
          year: this.myObj.year,
          familyWise: true,
          userID: this.$store.state.userData.userID,
          feeIDs: [],
        };

        if (pObj.cID[0] == 0) pObj.cID = [];
        if (pObj.secID[0] == 0) pObj.secID = [];

        // console.log(pObj);

        var objData = await this.postObj({
          url:
            this.$store.state.domain +
            "feesChallan/" +
            controller +
            "?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&exp=false",
          body: pObj,
          message: "Done successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });

        let header = this.cHeadObj ? this.cHeadObj.valueBool : false;

        if (objData.length > 0) {
          var fcIDRange = objData[0] + "-" + objData[objData.length - 1];
          //console.log(fcIDRange);
          let url =
            `https://${this.reportDomain}.myskool.app/Fee/Challan?dbb=` +
            this.$store.state.userData.db +
            "&sID=" +
            0 +
            "&cID=" +
            this.$store.state.userData.cId +
            "&cls=" +
            this.myObj.classIDs +
            "&sec=" +
            this.myObj.secIDs +
            "&feeType=" +
            this.myObj.feesTypeIDs +
            "&fCode=" +
            ffc +
            "&fcID=" +
            fcIDRange +
            "&duedate=" +
            this.settings.dueDate +
            "&header=" +
            header;

          let urlSelectedMonth = url + "&month=" + this.selectedMonths;

          // console.log(url);
          window.open(url, "_blank");
        }
      } catch (error) {
      } finally {
        this.iPrint = false;
      }
    },
    chunkArray(array, chunkSize) {
      try {
        console.log("Chunk started...");
        let results = [];
        while (array.length) {
          results.push(array.splice(0, chunkSize));
          console.log("Pushing...");
        }
        console.log("Result...", results);
        return results;
      } catch (error) {
        console.error(error);
      }
    },
    async saveFee() {
      this.CheckType();
      this.CheckMonths();
      this.CheckYear();
      this.CheckSec();
      this.CheckClass();
      if (
        this.CheckType() != false &&
        this.CheckMonths() != false &&
        this.CheckYear() != false &&
        this.CheckSec() != false &&
        this.CheckClass() != false
      ) {
        if (this.classID[0] !== 0) {
          this.myObj.classIDs = this.classID;
        }
        if (this.secID[0] !== 0) {
          // console.log(this.secID);
          this.myObj.secIDs = this.secID;
        }
        // console.log(this.myObj);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/CreateFeeAll?db=" +
            this.$store.state.userData.db,
          body: this.myObj,
          message: "Fee created successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });

        if (status) {
          this.prevObj = this.myObj;
          this.myObj = {
            id: 0,
            year: new Date().getFullYear(),
            userID: this.$store.state.userData.userID,
            feesTypeIDs: [],
            months: [],
            classIDs: [],
            secIDs: [],
            campusID: this.$store.state.userData.cId,
          };
          this.classID = [0];
          this.admittedSections = this.admittedSections = [
            { section: "All Sections", id: 0 },
          ];
          this.secID = [0];
          this.request = false;
        }
      }
    },
    async holdFee() {
      this.CheckType();
      this.CheckMonths();
      this.CheckYear();
      this.CheckSec();
      this.CheckClass();
      if (
        this.CheckType() != false &&
        this.CheckMonths() != false &&
        this.CheckYear() != false &&
        this.CheckSec() != false &&
        this.CheckClass() != false
      ) {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to hold the selected fee?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Hold it!",
        });

        if (result.isConfirmed) {
          this.holding = true;

          if (this.classID[0] !== 0) {
            this.myObj.classIDs = this.classID;
          }
          if (this.secID[0] !== 0) {
            // console.log(this.secID);
            this.myObj.secIDs = this.secID;
          }
          // console.log(this.myObj);
          delete this.myObj.onBehalf;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Fees/HoldFeeAll?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Fee holded successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.holding = false;
          if (status) {
            this.myObj = {
              id: 0,
              year: new Date().getFullYear(),
              userID: this.$store.state.userData.userID,
              feesTypeIDs: [],
              months: [],
              classIDs: [],
              secIDs: [],
              campusID: this.$store.state.userData.cId,
            };
            this.classID = [0];
            this.admittedSections = this.admittedSections = [
              { section: "All Sections", id: 0 },
            ];
            this.secID = [0];
            this.holding = false;
          }
        }
      }
    },
    async unholdFee() {
      this.CheckType();
      this.CheckMonths();
      this.CheckYear();
      this.CheckSec();
      this.CheckClass();
      if (
        this.CheckType() != false &&
        this.CheckMonths() != false &&
        this.CheckYear() != false &&
        this.CheckSec() != false &&
        this.CheckClass() != false
      ) {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to unhold the selected fee?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Unhold it!",
        });

        if (result.isConfirmed) {
          this.holding = true;

          if (this.classID[0] !== 0) {
            this.myObj.classIDs = this.classID;
          }
          if (this.secID[0] !== 0) {
            // console.log(this.secID);
            this.myObj.secIDs = this.secID;
          }
          // console.log(this.myObj);
          delete this.myObj.onBehalf;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Fees/UnholdFeeAll?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Fee unhold successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.holding = false;
          if (status) {
            this.myObj = {
              id: 0,
              year: new Date().getFullYear(),
              userID: this.$store.state.userData.userID,
              feesTypeIDs: [],
              months: [],
              classIDs: [],
              secIDs: [],
              campusID: this.$store.state.userData.cId,
            };
            this.classID = [0];
            this.admittedSections = this.admittedSections = [
              { section: "All Sections", id: 0 },
            ];
            this.secID = [0];
            this.holding = false;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
<style>
.my-collapsing {
  height: 55px;
  overflow: hidden;
}
.my-collapsing.adjust {
  height: 39px;
}
.my-buttons {
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
}
/* .swal2-styled {
  margin: 0.3125em;
} */
.v-select.set_multi .vs__dropdown-toggle {
  max-height: 300px !important;
  overflow-y: auto;
}
</style>
